<template>
  <v-app>
    <v-app-bar
      app
      color="white"
    >
      <div class="d-flex align-center">
        <svg version="1.1" id="logo_abet" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 449 54.7" style="enable-background:new 0 0 449 54.7;" xml:space="preserve">
            <g id="extra">
              <path d="M399.6,2.7l-2,6.6c-0.2,0.7,0.3,1.3,1,1.3h10.9c0.6,0,1,0.5,1,1v40.1c0,0.6,0.5,1,1,1h8.1c0.6,0,1-0.5,1-1V11.7
                c0-0.6,0.5-1,1-1h10.9c0.7,0,1.2-0.7,1-1.3l-2-6.6c-0.1-0.4-0.5-0.7-1-0.7h-30.1C400.1,2,399.7,2.3,399.6,2.7"></path>
              <path d="M182.3,43.1V3c0-0.6-0.5-1-1-1h-8.1c-0.6,0-1,0.5-1,1v48.7c0,0.6,0.5,1,1,1h21.4c0.5,0,0.8-0.3,1-0.7l2-6.6
                c0.2-0.7-0.3-1.3-1-1.3h-13.2C182.8,44.1,182.3,43.7,182.3,43.1"></path>
              <path d="M214.6,32.7l5.3-17.9c0.1-0.5,0.8-0.5,1,0l5.3,17.9c0.2,0.7-0.3,1.3-1,1.3h-9.7C214.8,34,214.4,33.3,214.6,32.7
                M242.4,51.4L227.8,2.7c-0.1-0.4-0.5-0.7-1-0.7h-13c-0.5,0-0.8,0.3-1,0.7l-14.6,48.7c-0.2,0.6,0.3,1.3,1,1.3h8.5
                c0.5,0,0.8-0.3,1-0.7l2.6-8.7c0.1-0.4,0.5-0.7,1-0.7h16.1c0.4,0,0.8,0.3,1,0.7L232,52c0.1,0.4,0.5,0.7,1,0.7h8.5
                C242.1,52.7,242.6,52.1,242.4,51.4"></path>
              <path d="M377.9,32.7l5.3-17.9c0.1-0.5,0.8-0.5,1,0l5.3,17.9c0.2,0.7-0.3,1.3-1,1.3h-9.7C378.2,34,377.7,33.3,377.9,32.7
                M405.7,51.4L391.2,2.7c-0.1-0.4-0.5-0.7-1-0.7h-13c-0.5,0-0.8,0.3-1,0.7l-14.6,48.7c-0.2,0.6,0.3,1.3,1,1.3h8.5
                c0.5,0,0.8-0.3,1-0.7l2.6-8.7c0.1-0.4,0.5-0.7,1-0.7h16.1c0.4,0,0.8,0.3,1,0.7l2.6,8.7c0.1,0.4,0.5,0.7,1,0.7h8.5
                C405.5,52.7,405.9,52.1,405.7,51.4"></path>
              <path d="M445.5,52.7h-8.1c-0.6,0-1-0.5-1-1V3c0-0.6,0.5-1,1-1h8.1c0.6,0,1,0.5,1,1v48.7C446.5,52.3,446.1,52.7,445.5,52.7"></path>
              <path d="M311.5,52.7h-8.1c-0.6,0-1-0.5-1-1V3c0-0.6,0.5-1,1-1h8.1c0.6,0,1,0.5,1,1v48.7C312.6,52.3,312.1,52.7,311.5,52.7"></path>
              <path d="M280,2.7l-9.4,29.9c-0.3,0.9-1.6,0.9-1.9,0l-9.4-29.9c-0.1-0.4-0.5-0.7-1-0.7h-11.7c-0.6,0-1,0.5-1,1v48.7c0,0.6,0.5,1,1,1
                h8.1c0.6,0,1-0.5,1-1V24.3c0-0.9,1.2-1,1.5-0.2l7.6,27.9c0.1,0.4,0.5,0.7,1,0.7h7.6c0.5,0,0.9-0.3,1-0.7l7.6-27.9
                c0.2-0.9,1.5-0.7,1.5,0.2v27.4c0,0.6,0.5,1,1,1h8.1c0.6,0,1-0.5,1-1V3c0-0.6-0.5-1-1-1h-11.7C280.5,2,280.1,2.3,280,2.7"></path>
              <path d="M348.3,3v29c0,0.8-1.2,1.1-1.5,0.3L335,2.6c-0.2-0.4-0.5-0.6-0.9-0.6h-11.7c-0.6,0-1,0.5-1,1v48.7c0,0.6,0.5,1,1,1h8.1
                c0.6,0,1-0.5,1-1v-29c0-0.8,1.2-1.1,1.5-0.3l11.7,29.7c0.2,0.4,0.5,0.6,0.9,0.6h11.7c0.6,0,1-0.5,1-1V3c0-0.6-0.5-1-1-1h-8.1
                C348.7,2,348.3,2.5,348.3,3"></path>
              <path d="M122.7,2.7l-2,6.6c-0.2,0.7,0.3,1.3,1,1.3h10.9c0.6,0,1,0.5,1,1v40.1c0,0.6,0.5,1,1,1h8.1c0.6,0,1-0.5,1-1V11.7
                c0-0.6,0.5-1,1-1h10.9c0.7,0,1.2-0.7,1-1.3l-2-6.6c-0.1-0.4-0.5-0.7-1-0.7h-30.1C123.2,2,122.8,2.3,122.7,2.7"></path>
              <path d="M101.4,43.1V30.4c0-0.6,0.5-1,1-1h12.7c0.6,0,1-0.5,1-1v-6.6c0-0.6-0.5-1-1-1h-12.7c-0.6,0-1-0.5-1-1v-8.1c0-0.6,0.5-1,1-1
                h14.5c0.4,0,0.8-0.3,1-0.7l2-6.6c0.2-0.6-0.3-1.3-1-1.3H92.2c-0.6,0-1,0.5-1,1v48.7c0,0.6,0.5,1,1,1h32c0.6,0,1-0.5,1-1v-6.6
                c0-0.6-0.5-1-1-1h-21.8C101.8,44.1,101.4,43.7,101.4,43.1"></path>
              <path d="M70.9,44.1H61c-0.6,0-1-0.4-1-1V30.4c0-0.6,0.4-1,1-1h10.2c4.2,0,7.6,3.6,7.3,7.8C78.3,41.2,74.9,44.1,70.9,44.1 M61,10.6
                h4.6c2.7,0,5,1.9,5.3,4.6c0.3,3-2.1,5.6-5.1,5.6H61c-0.6,0-1-0.5-1-1v-8.1C60,11.1,60.5,10.6,61,10.6 M76.8,22.6
                c2.3-2.3,3.6-5.4,3.6-8.9C80.1,7.2,74.5,2,67.9,2h-17c-0.6,0-1,0.5-1,1v48.7c0,0.6,0.5,1,1,1h21.7c8.3,0,15.3-6.6,15.3-14.9
                c0-6.9-4.6-12.6-10.9-14.4C76.6,23.4,76.5,22.9,76.8,22.6"></path>
            </g>
            <path id="primalettera" d="M18.8,32.7l5.3-17.9c0.1-0.5,0.8-0.5,1,0l5.3,17.9c0.2,0.7-0.3,1.3-1,1.3h-9.7C19,34,18.6,33.3,18.8,32.7
              M46.6,51.4L32,2.7c-0.1-0.4-0.5-0.7-1-0.7h-13c-0.5,0-0.8,0.3-1,0.7L2.5,51.4c-0.2,0.6,0.3,1.3,1,1.3H12c0.5,0,0.8-0.3,1-0.7
              l2.6-8.7c0.1-0.4,0.5-0.7,1-0.7h16.1c0.4,0,0.8,0.3,1,0.7l2.6,8.7c0.1,0.4,0.5,0.7,1,0.7h8.5C46.3,52.7,46.8,52.1,46.6,51.4"></path>
        </svg>
      </div>

      <v-spacer></v-spacer>

      <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <PostComponent/>
    </v-main>
  </v-app>
</template>

<script>
import PostComponent from './components/PostComponent.vue'

export default {
  name: 'App',

  components: {
    PostComponent,
  },

  data: () => ({
    //
  }),
};
</script>

<style scoped>
    svg#logo_abet {
        display: block;
        height: 25px;
    }

</style>
