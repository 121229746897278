<template>
    <v-autocomplete
        @change="getValue"
        v-model="select"
        :loading="loading"
        :items="entries"
        :search-input.sync="search"
        cache-items
        class="mx-4"
        flat
        hide-no-data
        hide-details
        :item-value="value_field"
        :item-text="text_field"
        :label="select_label"
        :prepend-icon="select_icon"
        
    ></v-autocomplete>
</template>

<script>

export default {
  name: 'SelectBox',
  props: {
    entries: Array,
    label: String,
    icon: String,
    text_field: String,
    value_field: String,
    selected: String
  },
  data() {
    return {
        /*select_entries: this.entries,*/
        error: '',
        text: '',
        loading: false,
        items: [],
        search: null,
        select: null,
        select_label: this.label,
        select_icon: this.icon
    }
  },
  watch: {
      search (val) {
        val && val !== this.select && this.querySelections(val)
      },
    },
    methods: {
      querySelections (v) {
        this.loading = true
        // Simulated ajax query
        setTimeout(() => {
          this.items = this.entries.map(item => item[this.text_field]).filter(e => {
            return (e || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1
          })
          this.loading = false
        }, 500)
      },
      getValue(){
        this.$emit('getSelectedValue', this.select);
      },

      formattedTextField(text_field, value_field){
          return text_field + ' - ' + value_field;
      }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>