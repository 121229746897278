<template>
  <div class="container">
   <v-app-bar
      absolute
      color="#6A76AB"
      dark
    >
      <!--v-toolbar-title>Color selection</v-toolbar-title-->
      <SelectBox
        :entries="colors"
        label="Colors"
        icon="mdi-database-search"
        text_field="ICDSCL1"
        value_field="ICCDCOL"
        @getSelectedValue="getSelectedColor"
      >
      </SelectBox>

      <SelectBox
        :entries="finishes"
        label="Finishes"
        icon="mdi-database-search"
        text_field="FIDSFI1"
        value_field="FICDFIN"
        @getSelectedValue="getSelectedFinishes"
      >
      </SelectBox>

      <SelectBox
        :entries="dimensions"
        label="Dimensions"
        icon="mdi-database-search"
        text_field="DIDSDIM"
        value_field="DICDDIM"
        @getSelectedValue="getSelectedDimensions"
      >
      </SelectBox>

      <SelectBox
        :entries="type_codes"
        label="Type Code"
        icon="mdi-database-search"
        text_field="T2CDTIP"
        value_field="T2CDTIP"
        @getSelectedValue="getSelectedTypeCode"
      >
      </SelectBox>

      <v-btn
        depressed
        color="white"
        light
        @click="getResults()"
      >
        Find
      </v-btn>
    </v-app-bar>

    <div style="width:90%;margin: 100px auto;">
      <v-data-table
        :headers="headers"
        :items="laminati"
        :items-per-page="100"
        class="elevation-1"
      ></v-data-table>
    </div>
    
  </div>
</template>

<script>
import APIService from '../services/APIService'
import SelectBox from './SelectBox.vue'

export default {
  name: 'PostComponent',
  components: {
      SelectBox,
  },
  data() {
    return {
      colors: [],
      finishes: [],
      dimensions: [],
      type_codes: [],
      laminati: [],
      selected_color: '',
      selected_finishes: '',
      selected_dimensions: '',
      selected_typecode: '',
      error: '',
      text: '',
      headers:[
          {
            text: 'Codice tipo',
            align: 'start',
            sortable: false,
            value: 'CDTIP',
          },
          { text: 'Colore', value: 'CDCOL' },
          { text: 'Dimensione', value: 'CDDIM' },
          { text: 'Finitura', value: 'CDFIN' },
          { text: 'Spessore', value: 'SPESS' },
          { text: 'Collezione', value: 'GRSTP' },
        ],
    }
  },
  async created(){
    try {
      this.colors = await APIService.getColors();
      this.finishes = await APIService.getFinishes();
      this.dimensions = await APIService.getDimensions();
      this.type_codes = await APIService.getTypeCodes();
    } catch(err) {
      this.error = err.message;
    }
  },
  methods: {
    getSelectedColor(val){
      this.selected_color = val;
    },
    getSelectedFinishes(val){
      this.selected_finishes = val;
    },
    getSelectedDimensions(val){
      this.selected_dimensions = val;
    },
    getSelectedTypeCode(val){
      this.selected_typecode = val;
    },
    async getResults(){
      console.log(this.selected_color);
      console.log(this.selected_finishes);
      console.log(this.selected_dimensions);
      console.log(this.selected_typecode);

      try {
        this.laminati = await APIService.getLaminati(this.selected_color, this.selected_finishes, this.selected_dimensions);
        console.log(this.laminati)
      } catch(err) {
        this.error = err.message;
      }
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
