import axios from 'axios';

const url_colors = 'https://producibilita.abetlaminati.com:5000/api/colors_prod';
const url_finishes = 'https://producibilita.abetlaminati.com:5000/api/finishes';
const url_dimensions = 'https://producibilita.abetlaminati.com:5000/api/dimensions';
const url_type_codes = 'https://producibilita.abetlaminati.com:5000/api/type_codes';
const url_laminati = 'https://producibilita.abetlaminati.com:5000/api/laminati';

// const url_colors = 'http://localhost:5000/api/colors';
// const url_finishes = 'http://localhost:5000/api/finishes';
// const url_dimensions = 'http://localhost:5000/api/dimensions';
// const url_type_codes = 'http://localhost:5000/api/type_codes';
// const url_laminati = 'http://localhost:5000/api/laminati';

class APIService {
    // Get Colors
    static getColors(){
        return new Promise( (resolve, reject) => {
            try {
                axios.get(url_colors).then(response => 
                    resolve(
                        response.data.map(color => ({
                            ...color,
                            createdAt: new Date(color.createdAt)
                        }))
                    )
                );
               /* const data = res.data;
                console.log('res: ' +res.data);
                */
            } catch(err) {
                reject(err);
            }
        });
    }


    // Get Finishes
    static getFinishes(){
        return new Promise( (resolve, reject) => {
            try {
                axios.get(url_finishes).then(response => 
                    resolve(
                        response.data.map(finish => ({
                            ...finish,
                            createdAt: new Date(finish.createdAt)
                        }))
                    )
                );
            } catch(err) {
                reject(err);
            }
        });
    }


    // Get Dimensions
    static getDimensions(){
        return new Promise( (resolve, reject) => {
            try {
                axios.get(url_dimensions).then(response => 
                    resolve(
                        response.data.map(dimension => ({
                            ...dimension,
                            createdAt: new Date(dimension.createdAt)
                        }))
                    )
                );
            } catch(err) {
                reject(err);
            }
        });
    }


    // Get Type Codes
    static getTypeCodes(){
        return new Promise( (resolve, reject) => {
            try {
                axios.get(url_type_codes).then(response => 
                    resolve(
                        response.data.map(type_code => ({
                            ...type_code,
                            createdAt: new Date(type_code.createdAt)
                        }))
                    )
                );
            } catch(err) {
                reject(err);
            }
        });
    }


    // Get Type Codes
    static getLaminati(color = '', finish = '', dimensions = '', type_code = ''){
        const params = {}
        if(color != ''){
            params.CDCOL = color;
        }
        if(finish != ''){
            params.CDFIN = finish;
        }
        if(dimensions != ''){
            params.CDDIM = dimensions;
        }
        if(type_code != ''){
            params.CDTIP = type_code;
        }
        return new Promise( (resolve, reject) => {
            try {
                axios.get(url_laminati, {params: params}).then(response => 
                    resolve(
                        response.data.map(posts => ({
                            ...posts,
                            createdAt: new Date(posts.createdAt)
                        }))
                    )
                );
            } catch(err) {
                reject(err);
            }
        });
    }

    // Create Posts

    // Delete Posts

}

export default APIService;